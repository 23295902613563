import * as React from 'react'
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.714 5.143H0V.857A.857.857 0 0 1 .857 0h4.286v1.714H1.714v3.429ZM24 5.143h-1.714V1.714h-3.429V0h4.286A.857.857 0 0 1 24 .857v4.286ZM5.143 24H.857A.857.857 0 0 1 0 23.143v-4.286h1.714v3.429h3.429V24ZM23.143 24h-4.286v-1.714h3.429v-3.429H24v4.286a.857.857 0 0 1-.857.857ZM12.857 12.857v-1.775A6.01 6.01 0 0 0 18 5.142a.857.857 0 0 0-.857-.856 6.007 6.007 0 0 0-6 6v2.571h1.714ZM14.571 13.714H9.43a.857.857 0 0 0-.858.857v1.715h1.715a.857.857 0 1 1 0 1.714H8.57v1.714a3.429 3.429 0 0 0 6.858 0v-5.143a.857.857 0 0 0-.858-.857ZM11.61 4.923A5.986 5.986 0 0 0 6.856 2.57.857.857 0 0 0 6 3.43a6 6 0 0 0 3.56 5.475 7.699 7.699 0 0 1 2.05-3.981Z"
    />
  </svg>
)
export default SvgComponent
