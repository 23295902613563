const ContentCard = ({ children, title, imgUrl, imgAlt }) => {
  return (
    <div className="flex bg-white rounded-lg overflow-hidden p-[0.62rem]">
      <div className="w-[4.125rem] rounded-lg overflow-hidden shrink-0">
        <img src={imgUrl} alt={imgAlt} className="object-cover h-full w-full" />
      </div>
      <div className="ml-[0.81rem]">
        <h4 className="text-base text-primaryDark font-bold mb-[0.31rem]">
          {title}
        </h4>
        {children}
      </div>
    </div>
  )
}

export default ContentCard
