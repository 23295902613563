import { useContext } from 'react'
import { AppContext } from 'src/context/AppContext'
import RecommendedProduct from './RecommendedProduct'
import Drawer from './Drawer'
import ContentCard from './ui/ContentCard'

const PlantHealthDrawer = ({ isOpen, closeDrawer }) => {
  const { state } = useContext(AppContext)
  const { healthData } = state
  return (
    <Drawer title="Plant health" isOpen={isOpen} closeDrawer={closeDrawer}>
      <h3 className="text-xl text-primaryDark font-bold mb-[0.66rem]">
        Disease diagnosing
      </h3>
      <div className="flex flex-col gap-products">
        {healthData?.disease?.suggestions &&
          healthData.disease.suggestions.slice(0, 3).map((item) => (
            <ContentCard
              key={item.id}
              title={item.name}
              imgUrl={item.similar_images?.[0]?.url_small}
              imgAlt={item.name}
            >
              <div className="flex flex-wrap gap-[0.31rem]">
                {item?.details?.classification?.length > 0 &&
                  item.details.classification.slice(0, 3).map((name) => (
                    <small
                      key={name}
                      className="rounded-md bg-primaryLighter px-[0.375rem] py-[0.1875rem] text-primary300 text-[0.625rem]"
                    >
                      {name}
                    </small>
                  ))}
              </div>
            </ContentCard>
          ))}
      </div>
      <h3 className="text-xl text-primaryDark font-bold mb-[0.66rem] mt-[1.25rem]">
        Growing guides
      </h3>
      <div className="flex flex-col gap-products">
        <ContentCard
          title="Growing hydrangeas"
          imgUrl="/assets/growing-guide.png"
          imgAlt="Growing guide"
        >
          <p className="text-xs leading-[1.0625rem] text-grey500 font-normal">
            Hydrangeas have a romantic elegance reminiscent of times gone by,
            and with their large, long- flowering...
          </p>
        </ContentCard>
      </div>
      <h4 className="mt-[1.25rem] text-xl font-bold text-primaryDark">
        Recommended product
      </h4>
      <div className="flex flex-col mt-[0.63rem] gap-products">
        {/* FIXED FOR NOW */}
        <RecommendedProduct
          imgUrl="/assets/product-1.png"
          title="Miracle-Gro® Peat Free Premium All Purpose Compost"
        />
        <RecommendedProduct
          imgUrl="/assets/product-2.png"
          title="Miracle-Gro® All Purpose Concentrated Liquid Plant Food"
        />
      </div>
    </Drawer>
  )
}

export default PlantHealthDrawer
