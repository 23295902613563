import Logo from './svgs/Logo'

const Header = () => {
  return (
    <div className="relative z-10 pt-[1.88rem] pb-[3.75rem] flex flex-col items-center">
      <Logo />
      <h2 className="text-white font-bold text-lg mt-[0.62rem]">
        Plant AI advice
      </h2>
    </div>
  )
}

export default Header
