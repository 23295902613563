import { useEffect, useRef, useState } from 'react'
import Menu from './components/ui/Menu'

import UploadImage from './components/UploadImage'
import { AppContext } from './context/AppContext'
import PlantDetailsDrawer from './components/PlantDetailsDrawer'
import PlantHealthDrawer from './components/PlantHealthDrawer'
import Header from './components/Header'
import { CSSTransition } from 'react-transition-group'

const uploadFileData = async (fileData) => {
  const response = await fetch(
    process.env.REACT_APP_API_URL ?? 'http://localhost:3001',
    {
      method: 'POST',
      body: fileData,
    }
  )

  const { plant, health } = await response.json()

  return {
    plant,
    health,
  }
}

function App() {
  const [state, setState] = useState({
    fetching: false,
    plantData: null,
    healthData: null,
    uploadedImage: null,
    action: null,
    imageUrl: null,
  })

  const [isPlantDetailsOpen, setPlantDetailsOpen] = useState(false)
  const [showPlantHealth, setShowPlantHealth] = useState(false)
  const [showUploadedImage, setShowUploadedImage] = useState(false)

  const uploadedImgRef = useRef(null)

  useEffect(() => {
    let isCancel = false
    let fileReader

    if (!state.uploadedImage || state.fetching) return

    let objectUrl = null
    // Create url to uploaded file
    if (state.uploadedImage) {
      objectUrl = URL.createObjectURL(state.uploadedImage)
      setState({
        ...state,
        fetching: true,
        imageUrl: objectUrl,
      })
      setShowUploadedImage(true)
    } else {
      setState({
        ...state,
        fetching: true,
      })
    }

    // Original source for the image upload was https://blog.logrocket.com/using-filereader-api-preview-images-react/
    const fetchImage = async () => {
      if (state.uploadedImage) {
        fileReader = new FileReader()

        fileReader.onload = (e) => {
          const { result } = e.target

          if (!result || isCancel) {
            // @todo handle error
            return
          }

          //@todo add error
          uploadFileData(result)
            .then((res) => {
              setState({
                ...state,
                fetching: false,
                plantData: res.plant,
                healthData: res.health,
                imageUrl: objectUrl,
              })

              // Show drawer or health page
              if (state.action === 'identify') {
                setPlantDetailsOpen(true)
              } else {
                setShowPlantHealth(true)
              }

              Promise.resolve()
            })
            .catch((er) => Promise.reject(er))
        }
        fileReader.readAsDataURL(state.uploadedImage)
      }
    }

    fetchImage()

    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }

      if (objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [state.uploadedImage])

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
      }}
    >
      <div className="h-screen max-w-lg mx-auto relative">
        <div className="h-full flex flex-col">
          <Header />
          <UploadImage />
          <Menu />
        </div>
        <div className="absolute left-0 top-0 w-full h-full z-0 overflow-hidden">
          <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-[rgb(0,0,0,0.15)] to-[rgb(0,0,0,0.2)] z-20"></div>
          <CSSTransition
            nodeRef={uploadedImgRef}
            classNames="uploaded-image"
            in={state.imageUrl && showUploadedImage}
            timeout={400}
            unmountOnExit
          >
            <div
              ref={uploadedImgRef}
              className="uploaded-image absolute left-0 top-0 w-full h-full z-[11]"
            >
              <img
                src={state.imageUrl}
                alt="Background"
                className="object-cover w-full h-full"
              />
            </div>
          </CSSTransition>
          <div className="absolute left-0 top-0 w-full h-full z-10">
            <img
              src="/assets/blurred-background.png"
              alt="Background"
              className="object-cover w-full h-full"
            />
          </div>
        </div>

        <PlantDetailsDrawer
          isOpen={isPlantDetailsOpen}
          closeDrawer={() => {
            setPlantDetailsOpen(false)
            setShowUploadedImage(false)
          }}
        />
        <PlantHealthDrawer
          isOpen={showPlantHealth}
          closeDrawer={() => {
            setShowPlantHealth(false)
            setShowUploadedImage(false)
          }}
        />
      </div>
    </AppContext.Provider>
  )
}

export default App
