import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import Close from './svgs/Close'

const Drawer = ({ title, children, isOpen, closeDrawer }) => {
  const nodeRef = useRef(null)
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isOpen}
      classNames="drawer"
      timeout={400}
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className="drawer fixed bottom-0 pb-[4.8rem] left-0 w-full h-[720px] bg-primaryOpacity rounded-t-drawer z-20 p-[1.875rem] pr-0 backdrop-blur-panel flex flex-col"
      >
        <div className="flex justify-between border-b border-separator mb-[1.25rem] mr-[1.875rem] shrink-0">
          <h2 className="text-xxl text-primary font-bold mb-[1.25rem]">
            {title}
          </h2>
          <button
            onClick={() => closeDrawer()}
            className="inline-flex justify-center items-center bg-white rounded-full h-[1.875rem] aspect-square"
          >
            <Close className="h-[0.625rem]" />
          </button>
        </div>
        <div className="grow overflow-y-auto pb-[1.875rem] pr-[1.875rem]">
          {children}
        </div>
        <div className="absolute bottom-[4.8rem] left-0 w-full h-[2rem] bg-gradient-to-t from-[rgba(53,53,53,0.15)] to-[rgba(0,0,0,0)]"></div>
      </div>
    </CSSTransition>
  )
}

export default Drawer
