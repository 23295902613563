import { useContext } from 'react'
import { AppContext } from 'src/context/AppContext'
import RecommendedProduct from './RecommendedProduct'
import Drawer from './Drawer'

const PlantDetailsDrawer = ({ isOpen, closeDrawer }) => {
  const { state } = useContext(AppContext)
  const { plantData, imageUrl } = state

  return (
    <Drawer title="Identify plant" isOpen={isOpen} closeDrawer={closeDrawer}>
      {plantData && (
        <>
          <div className="w-full flex flex-col items-center mb-[0.81rem]">
            <div className="w-[6.8125rem] rounded-md overflow-hidden aspect-plantImage border-2 border-white">
              <img
                src={imageUrl}
                alt={plantData?.name}
                className="object-cover w-full h-full"
              />
            </div>
            <h2 className="text-primary text-xl font-bold mt-[0.75rem]">
              {plantData?.name ?? 'Unknown'}
            </h2>
          </div>
          <p className="text-grey500 text-sm leading-paragraph font-normal text-center pb-[1.25rem] mb-[1.25rem]">
            {plantData?.details?.description?.value}
          </p>
          <h3 className="text-base font-extrabold text-primaryDark mb-[0.62rem]">
            Recommended product
          </h3>

          <div className="flex flex-col gap-products">
            {/* FIXED FOR NOW */}
            <RecommendedProduct
              imgUrl="/assets/product-1.png"
              title="Miracle-Gro® Peat Free Premium All Purpose Compost"
            />
            <RecommendedProduct
              imgUrl="/assets/product-2.png"
              title="Miracle-Gro® All Purpose Concentrated Liquid Plant Food"
            />
          </div>
        </>
      )}
    </Drawer>
  )
}

export default PlantDetailsDrawer
