import Button from '../components/ui/Button'
import ScanPlant from '../components/svgs/ScanPlant'
import Health from '../components/svgs/Health'
import ScanImage from '../components/svgs/ScanImage'
import { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from 'src/context/AppContext'
import Header from './Header'

const imageMimeType = /image\/(png|jpg|jpeg)/i

const UploadImage = () => {
  const { state, setState } = useContext(AppContext)
  const { fetching } = state
  const fileRef = useRef(null)

  const uploadImage = (type) => {
    setState({
      ...state,
      action: type,
    })
    fileRef.current.click()
  }

  const handleFileUpload = (e) => {
    const file = e.target.files?.[0]
    if (!file.type.match(imageMimeType)) {
      // @todo replace with error msg
      alert('Image mime type is not valid')
      return
    }

    setState({
      ...state,
      uploadedImage: file,
    })

    e.target.value = null
  }

  const word = 'Identifying...'
  const [typeIndex, setTypeIndex] = useState(0)

  useEffect(() => {
    let interval = setInterval(() => {
      if (typeIndex < -word.length) {
        setTypeIndex(word.length)
      } else {
        setTypeIndex(typeIndex - 1)
      }
    }, 840 / word.length)

    return () => clearInterval(interval)
  }, [typeIndex])

  return (
    <div className="relative z-20 grow flex flex-col items-center justify-end">
      <div className="grow flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="relative">
            <img
              src="/assets/scan-frame.png"
              alt="Scan"
              className="w-[14.90625rem]"
            />
            {fetching && (
              <img
                src="/assets/scan-progress-frame.png"
                alt="Scan"
                className="w-[14.90625rem] absolute left-0 top-0 h-full scan-animation"
              />
            )}
          </div>
          <p className="inline-block mt-[1.25rem] text-white text-[1.125rem] font-semibold">
            {fetching ? (
              <span className="w-[6.2rem]">
                {word.substring(0, Math.abs(typeIndex))}
                &nbsp;
              </span>
            ) : (
              <span>Choose an option</span>
            )}
          </p>
        </div>
      </div>

      <div className="w-full flex gap-1 p-[1.25rem] justify-between relative z-20">
        <Button
          className="text-white bg-primary shadow-primary"
          onClick={() => uploadImage('identify')}
        >
          <ScanPlant className="h-[1.5rem] text-white" />
          Identify plant
        </Button>
        <Button
          className="text-primary bg-white shadow-primary"
          onClick={() => uploadImage('health')}
        >
          <Health className="h-[1.5rem] text-primary" />
          Plant health
        </Button>
      </div>

      <input
        type="file"
        ref={fileRef}
        className="hidden"
        onChange={handleFileUpload}
      />
    </div>
  )
}

export default UploadImage
