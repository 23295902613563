import * as React from 'react'
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <g fill="currentColor">
      <path d="m6.934 11 2.709-4.515a1 1 0 0 1 1.786.144l3.251 8.128 1.963-3.272A1 1 0 0 1 17.5 11h6.95a11.991 11.991 0 0 0-23.9 0h6.384Z" />
      <path d="m18.066 13-2.709 4.515A.999.999 0 0 1 14.5 18h-.08a1 1 0 0 1-.849-.626L10.32 9.243l-1.963 3.272A.999.999 0 0 1 7.5 13H.55a11.99 11.99 0 0 0 23.9 0h-6.384Z" />
    </g>
  </svg>
)
export default SvgComponent
