const RecommendedProduct = ({ imgUrl, title }) => {
  return (
    <div className="flex w-full bg-white rounded-xl">
      <div className="shrink-0 flex w-[6.5rem] h-full p-[1rem] border-r-2 border-primaryLight items-center justify-center">
        <img src={imgUrl} alt={title} className="w-full h-full p-4" />
      </div>
      <div className="p-[1rem]">
        <h4 className="text-primaryDark font-bold leading-[1.3125rem]">
          {title}
        </h4>
        <button className="btn-buy-now mt-[1rem]">Buy now</button>
      </div>
    </div>
  )
}

export default RecommendedProduct
