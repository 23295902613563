import clsx from 'clsx'

const Button = ({ children, className = '', ...rest }) => {
  return (
    <button
      className={clsx(
        className,
        'rounded-full px-[1.885rem] py-[0.375rem] h-[3.125rem] text-xs inline-flex items-center gap-[0.878rem]'
      )}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
